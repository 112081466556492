/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import TDate from './boot-vue-functions'

window.Vue = require('vue');

import VueLoading from 'vuejs-loading-plugin'
Vue.use(VueLoading, {
    dark: true, // default false
    text: 'Carregando..', // default 'Loading'
    loading: false, // default false
    classes: ['loading-screen-inspinia', 'animated', 'fadeIn'] // array, object or string
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */


/**Nd
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


const app = new Vue({
    el: '#app',
    methods: {
        dateFormatBR: TDate.dateBR,
        dateFormatUS: TDate.dateUS,
    },
    components: {

        LinkDestroyComponent: () => import('./components/LinkDestroyComponent' /* webpackChunkName: "js/async-link-destroy-component" */),
        OrderSummaryComponent: () => import('./components/OrderSummaryComponent' /* webpackChunkName: "js/async-order-summary-component" */),
        CheckoutComponent: () => import('./components/CheckoutComponent' /* webpackChunkName: "js/async-checkout-component" */),
        UserAddressComponent: () => import('./components/UserAddressComponent' /* webpackChunkName: "js/async-user-address-component" */),
        ViewCounterProduct: () => import('./components/ViewCounterProduct' /* webpackChunkName: "js/async-view_counter-product" */),
        PaymentComponent: () => {
            return new Promise((resolve, reject) => {
                let script = document.createElement('script');
                script.onload = () => {
                    resolve(import('./components/PaymentComponent'))
                };
                script.async = true;
                script.src = 'https://assets.pagar.me/checkout/1.1.0/checkout.js';
                document.head.appendChild(script);
            })
        }
    },
});

$('#app').tooltip({
    //selector: '[data-toggle="tooltip"]'
    selector: "[data-tooltip=tooltip]",
});
